import React from 'react';
import {
  Container,
  Grid,
} from '@material-ui/core';

import Page from 'src/components/Page';
import AllEvents from './allEvents';
import VerificationHistory from './verificationHistory';
import TopSection from './userInfo';
import TransactionHistory from './transactionHistory';
import AllMatches from './allMaches';
import Toolbar from './Toolbar';
import Prompt from './prompt';

const dummydata = {
  deviceType: '',
  createdAt: 0,
  createdIn: '',
  ryuBalance: 0,
  restrictedBalance: 0,
  withdrawableBalance: 0,
  username: '',
  pearlsBalance: 0,
  phoneNumber: '',
  gamesPlayed: {},
  transactionHistory: [],
  profilePicture: '',
  verificationStatus: '',
  verificationHistory: [],
  previousMatches: [],
  joinedMatches: [],
  events: [],
  gameIdToNameLookup: {},
  personName: '',
};

const dummyAddress = '';
// AWKcSQ5WKLSgyArVYvPyGbrdnDJ2yNkhQg

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.setData = this.setData.bind(this);
    this.handleClose = this.handleClose.bind(this);

    const password = localStorage.getItem('capw');
    const open = password == null || password === 'null';

    this.state = {
      data: dummydata,
      address: dummyAddress,
      lastCountry: 'N/A',
      lastRegion: 'N/A',
      lastEligible: false,
      personName: '',
      password,
      open,
    };
  }

  handleClose(password) {
    this.setState({
      password,
      open: false,
    });
    localStorage.setItem('capw', password);
  }

  setData(newData) {
    console.log('API did respond with data');

    let nameToUse = newData.name;
    if (nameToUse === newData.username) {
      nameToUse = 'Unknown';
    }

    this.setState({
      data: newData,
      address: newData.address,
      personName: nameToUse,
    });

    const { events } = newData;
    const eventsLength = events.length;

    if (eventsLength > 0) {
      events.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return 1;
        }
        return -1;
      });
      const lastE = events[eventsLength - 1];
      const locData = lastE.location;

      const {
        location,
        isValid,
      } = locData;

      let country = location;
      let region = 'N/A';

      const locationPieces = location.split(', ');
      if (locationPieces.length > 1) {
        ([region, country] = locationPieces);
      }

      this.setState({
        lastCountry: country,
        lastRegion: region,
        lastEligible: isValid,
      });
    }
  }

  render() {
    const {
      data,
      address,
      lastCountry,
      lastRegion,
      lastEligible,
      personName,
      password,
      open,
    } = this.state;

    return (
      <div>
        <Prompt
          handleClose={this.handleClose}
          open={open}
        />
        <Page
          title="Dashboard"
        >
          <Container maxWidth={false}>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Toolbar
                  setData={this.setData}
                  password={password}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TopSection
                  data={data}
                  address={address}
                  lastCountry={lastCountry}
                  lastRegion={lastRegion}
                  lastEligible={lastEligible}
                  personName={personName}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <VerificationHistory
                  data={data}
                  password={password}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <AllEvents data={data} />
              </Grid>
              <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <TransactionHistory data={data} />
              </Grid>
              <Grid
                item
                lg={8}
                md={12}
                xl={9}
                xs={12}
              >
                <AllMatches data={data} address={address} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      </div>
    );
  }
}

export default Dashboard;
