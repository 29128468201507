import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({
  className,
  password,
  setData,
  ...rest
}) => {
  const classes = useStyles();
  const [, getData] = useState('');
  const [userAddress, setUserAddress] = useState('');

  async function userDashboard() {
    await axios.get(
      `https://cnd.ryu.games/search?address=${userAddress}&password=${password}`
    )
      .then((res) => {
        const userData = res.data.result;
        getData(userData);
        setData(userData);
      }).catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box mt={3}>
        <Card>
          <CardContent>
            <Box maxWidth={500}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      className={classes.margin}
                      onClick={() => userDashboard()}
                    >
                      Search
                    </Button>
                  )
                }}
                placeholder="Search users by address or username"
                variant="outlined"
                // InputProps={{endAdornment: <YOUR_COPY_ICON_BUTTON />}}
                onChange={(e) => {
                  setUserAddress(e.target.value);
                }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  setData: PropTypes.func,
  password: PropTypes.string,
};

export default Toolbar;
