import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

class Prompt extends React.Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
  }

  save() {
    const {
      handleClose,
    } = this.props;
    const textfield = document.getElementById('passwordSubmission').value;
    if (textfield != null && textfield.length > 0) {
      handleClose(textfield);
    }
  }

  render() {
    const {
      open,
    } = this.props;

    return (
      <div>
        <Dialog open={open} onClose={() => this.save()} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Sign-in</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter in your password to use the dashboard.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="passwordSubmission"
              label="Password"
              type="text"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.save()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

Prompt.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

export default Prompt;
