import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TableSortLabel,
  Chip,
  Dialog,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { verification, openModal } = props;

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {moment(verification.timestamp).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          <Chip
            color="primary"
            label={verification.status}
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {Object.keys(verification).map((key) => {
                      return (
                        <TableCell key={key}>{key}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {Object.keys(verification).map((key) => {
                      const value = verification[key];
                      if (key === 'verificationKey') {
                        return (
                          <TableCell key={key} onClick={() => openModal(value)} align="right">{JSON.stringify(value)}</TableCell>
                        );
                      }
                      return (
                        <TableCell key={key} align="right">{JSON.stringify(value)}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

class VerificationHistory extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.state = {
      shouldBeOpen: false,
      license: null,
      selfie: null,
    };
  }

  closeModal() {
    this.setState({
      shouldBeOpen: false,
    });
  }

  openModal(verificationKey) {
    const { password } = this.props;
    axios.get(
      `https://cnd.ryu.games/imagesForVerification?verificationKey=${verificationKey}&password=${password}`
    )
      .then((res) => {
        const { result } = res.data;
        const {
          license,
          selfie,
          mailingAddress,
          legalName,
        } = result;

        console.log(`Legal Name: ${legalName}`);
        console.log(`Mailing Address: ${mailingAddress.replaceAll('::', ', ')}`);

        this.setState({
          license,
          selfie,
          shouldBeOpen: true,
        });
      }).catch((err) => {
        console.log(`There was an error loading the iamges: ${err.message}`);
      });
  }

  render() {
    const { data } = this.props;
    const {
      shouldBeOpen,
      license,
      selfie,
    } = this.state;
    const { verificationHistory } = data || [];
    verificationHistory.sort((a, b) => {
      if (a.timestamp < b.timestamp) {
        return 1;
      }
      return -1;
    });

    const licenseSrc = `data:image/jpeg;base64,${license}`;
    const selfieSrc = `data:image/jpeg;base64,${selfie}`;

    return (
      <div>
        <Dialog
          open={shouldBeOpen}
          onClose={() => this.closeModal()}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <img src={licenseSrc} alt="license" />
          <img src={selfieSrc} alt="selfie" />
        </Dialog>
        <Card>
          <CardHeader
            title="Verification History"
          />
          <Divider />
          <PerfectScrollbar>
            <Box
              minWidth={120}
              height={400}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell sortDirection="desc">
                      <Tooltip
                        enterDelay={300}
                        title="Sort"
                      >
                        <TableSortLabel
                          active
                          direction="desc"
                        >
                          Date
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {verificationHistory.map((order) => (
                    <Row
                      hover
                      key={order.verificationKey}
                      verification={order}
                      openModal={this.openModal}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            />
          </PerfectScrollbar>
        </Card>
      </div>
    );
  }
}

VerificationHistory.propTypes = {
  data: PropTypes.object,
  password: PropTypes.string,
};

Row.propTypes = {
  verification: PropTypes.object,
  openModal: PropTypes.func,
};

export default VerificationHistory;
