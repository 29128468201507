import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
  Avatar,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  table: {
    minWidth: 650,
  },
}));

function getElapsed(timestamp) {
  const now = (new Date()).getTime();
  const differnce = now - timestamp;

  const millisecondDifference = moment.duration(differnce).asMilliseconds();
  if (Math.round(millisecondDifference) < 1000) {
    return '(Under 1 second ago)';
  }

  const secondDifference = moment.duration(differnce).asSeconds();
  if (secondDifference < 60) {
    if (Math.round(secondDifference) === 1) {
      return '(1 second ago)';
    }
    return `(${Math.round(secondDifference)} seconds ago)`;
  }

  const minuteDifference = moment.duration(differnce).asMinutes();
  if (minuteDifference < 60) {
    if (Math.round(minuteDifference) === 1) {
      return '(1 minute ago)';
    }
    return `(${Math.round(minuteDifference)} minutes ago)`;
  }

  const hourDifference = moment.duration(differnce).asHours();
  if (hourDifference < 24) {
    if (Math.round(hourDifference) === 1) {
      return '(1 hour ago)';
    }
    return `(${Math.round(hourDifference)} hours ago)`;
  }

  const dayDifference = moment.duration(differnce).asDays();
  if (dayDifference < 7) {
    if (Math.round(dayDifference) === 1) {
      return '(1 day ago)';
    }
    return `(${Math.round(dayDifference)} days ago)`;
  }

  const weekDifference = moment.duration(differnce).asWeeks();
  if (weekDifference < 4) {
    if (Math.round(weekDifference) === 1) {
      return '(1 week ago)';
    }
    return `(${Math.round(weekDifference)} weeks ago)`;
  }

  const monthDifference = moment.duration(differnce).asMonths();
  if (monthDifference < 4) {
    if (Math.round(monthDifference) === 1) {
      return '(1 month ago)';
    }
    return `(${Math.round(monthDifference)} months ago)`;
  }

  const yearDifference = moment.duration(differnce).asYears();
  if (Math.round(yearDifference) === 1) {
    return '(1 year ago)';
  }
  return `(${Math.round(yearDifference)} years ago)`;
}

const TopSection = (props, { className, ...rest }) => {
  const classes = useStyles();

  const {
    data,
    address,
    lastCountry,
    lastRegion,
    lastEligible,
    personName,
  } = props;

  const {
    events,
    username,
    profilePicture,
    gamesPlayed,
    phoneNumber,
    deviceType,
    joinedMatches,
    previousMatches,
    appsflyerId,
    organic,
    ryuBalance,
    restrictedBalance,
  } = data;

  const matches = (joinedMatches).concat(previousMatches);
  const matchesLength = matches.length;
  const eventsLength = events.length;
  const restrictedString = `$${restrictedBalance.toFixed(2)}`;
  const balanceString = `$${ryuBalance.toFixed(2)} (${restrictedString} restricted)`;

  function FormRow1() {
    events.sort((a, b) => {
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      return -1;
    });

    let firstEvent = '';
    let lastEvent = '';
    if (eventsLength > 0) {
      const lastE = events[eventsLength - 1];
      const firstTS = events[0].timestamp;
      const lastTS = lastE.timestamp;

      const firstEventDate = moment(firstTS).format('MMM D, YYYY');
      const lastEventDate = moment(lastTS).format('MMM D, YYYY');

      firstEvent = `${firstEventDate} ${getElapsed(firstTS)}`;
      lastEvent = `${lastEventDate} ${getElapsed(lastTS)}`;
    }

    return (
      <>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Username</h4>
            <br />
            {username}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Name</h4>
            <br />
            {personName}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>First Event</h4>
            <br />
            {firstEvent}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Last Event</h4>
            <br />
            {lastEvent}
          </Paper>
        </Grid>
      </>
    );
  }

  function FormRow2() {
    return (
      <>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Total Events</h4>
            <br />
            {eventsLength}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Total Games</h4>
            <br />
            {Object.keys(gamesPlayed).length}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Phone</h4>
            <br />
            {phoneNumber}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Device Type</h4>
            <br />
            {deviceType}
          </Paper>
        </Grid>
      </>
    );
  }

  function FormRow3() {
    return (
      <>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Balance</h4>
            <br />
            {balanceString}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Total Matches</h4>
            <br />
            {matchesLength}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>AppsFlyer ID</h4>
            <br />
            {appsflyerId}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Is Organic</h4>
            <br />
            {organic ? 'Yes' : 'No'}
          </Paper>
        </Grid>
      </>
    );
  }

  function FormRow4() {
    return (
      <>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Country</h4>
            <br />
            {lastCountry}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Region</h4>
            <br />
            {lastRegion}
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>City</h4>
            <br />
            N/A
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Paper className={classes.paper}>
            <h4>Eligible for Cash</h4>
            <br />
            {lastEligible ? 'Yes' : 'No'}
          </Paper>
        </Grid>
      </>
    );
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div style={{ width: '100%' }} />
      <Box
        display="flex"
        flexDirection="row"
        p={0.5}
        m={0.5}
        bgcolor="background.paper"
      >
        <Box p={1}>
          <Avatar
            className={classes.large}
            component={RouterLink}
            src={profilePicture}
            to="/app/account"
          />
        </Box>
        <Box p={2}>
          <Typography
            className={classes.name}
            color="textPrimary"
            variant="h4"
          >
            {username}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {address}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <CardContent>
        <Box
          height={325}
          position="relative"
        >
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid container item xs={12} spacing={3}>
                <FormRow1 />
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <FormRow2 />
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <FormRow3 />
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <FormRow4 />
              </Grid>
            </Grid>
          </div>
        </Box>
      </CardContent>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      />
    </Card>
  );
};

TopSection.propTypes = {
  className: PropTypes.string,
  address: PropTypes.string,
  data: PropTypes.object,
  lastCountry: PropTypes.string,
  lastRegion: PropTypes.string,
  lastEligible: PropTypes.bool,
  personName: PropTypes.string,
};

export default TopSection;
