import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TableSortLabel,
  Chip, makeStyles,

} from '@material-ui/core';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  table: {
    minWidth: 650,
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const {
    transaction,
  } = props;
  const sortedObjKeys = Object.keys(transaction).sort((a, b) => a.localeCompare(b));

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {moment(transaction.transactionTime).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          <Chip
            color="primary"
            label={transaction.transactionType}
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      return (
                        <TableCell key={key}>{key}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      const value = transaction[key];
                      return (
                        <TableCell key={key} align="right">{JSON.stringify(value)}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TransactionHistory = (props, { className, ...rest }) => {
  const classes = useStyles();
  const { data } = props;
  const { transactionHistory } = data || [];
  transactionHistory.sort((a, b) => {
    if (a.transactionTime < b.transactionTime) {
      return 1;
    }
    return -1;
  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title="Transaction History"
      />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={120}
          height={430}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Type
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionHistory.map((order) => (
                <Row
                  hover
                  key={order.transactionTime}
                  transaction={order}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        />
      </PerfectScrollbar>
    </Card>
  );
};

TransactionHistory.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object
};

Row.propTypes = {
  transaction: PropTypes.object,
};

export default TransactionHistory;
