import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { event, gameIdToNameLookup } = props;
  const sortedObjKeys = Object.keys(event).sort((a, b) => a.localeCompare(b));

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {event.eventName}
        </TableCell>
        <TableCell>
          {gameIdToNameLookup[event.gameId] || event.gameId}
        </TableCell>
        <TableCell>
          {moment(event.timestamp).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          <Chip
            color={event.location.isValid ? 'secondary' : 'primary'}
            label={event.location.location}
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      return (
                        <TableCell key={key}>{key}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      const value = event[key];
                      return (
                        <TableCell key={key} align="right">{JSON.stringify(value)}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AllEvents = (props, { className, ...rest }) => {
  const classes = useStyles();
  const { data } = props;
  const { events, gameIdToNameLookup } = data;
  events.sort((a, b) => {
    if (a.timestamp < b.timestamp) {
      return 1;
    }
    return -1;
  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="All Events" />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={800}
          height={400}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Event Name
                </TableCell>
                <TableCell>
                  Game
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Location
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((order) => (
                <Row key={order.eventKey} event={order} gameIdToNameLookup={gameIdToNameLookup} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      />
    </Card>
  );
};

AllEvents.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

Row.propTypes = {
  event: PropTypes.object,
  gameIdToNameLookup: PropTypes.object,
};

export default AllEvents;
