import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

function getMatchStatus(match, address) {
  const {
    winners,
    payouts,
    option,
    endTime,
  } = match;

  const {
    ryuEntry,
    practiceEntry,
  } = option;

  if (endTime === 0) {
    if (ryuEntry > 0) {
      return `Ongoing, entry $${ryuEntry}`;
    }

    if (practiceEntry > 0) {
      return `Ongoing, entry ${practiceEntry} pearls`;
    }

    return 'Ongoing, social';
  }

  let index;
  const winnerCount = winners.length;
  for (let i = 0; i < winnerCount; i += 1) {
    if (winners[i] === address) {
      index = i;
      break;
    }
  }

  if (index != null) {
    const payout = payouts[index];
    const {
      practice,
      ryu,
    } = payout;

    if (ryu > 0) {
      return `Ended, won $${ryu}`;
    }

    if (practice > 0) {
      return `Ended, won ${practice} pearls`;
    }

    return 'Ended, won social';
  }

  if (ryuEntry > 0) {
    return `Ended, lost $${ryuEntry}`;
  }

  if (practiceEntry > 0) {
    return `Ended, lost ${practiceEntry} pearls`;
  }

  return 'Ended, lost social';
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const Row = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const {
    match,
    address,
    gameIdToNameLookup,
  } = props;

  const status = getMatchStatus(match, address);
  const won = status.includes('won');
  const ongoing = status.includes('Ongoing');

  const sortedObjKeys = Object.keys(match).sort((a, b) => a.localeCompare(b));

  let color = 'primary';
  if (won) {
    color = 'secondary';
  }

  if (ongoing) {
    color = '';
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {match.Match_ID}
        </TableCell>
        <TableCell>
          {gameIdToNameLookup[match.Game_ID] || match.Game_ID}
        </TableCell>
        <TableCell>
          {moment(match.endTime === 0 ? match.createdTime : match.endTime).format('MM/DD/YYYY')}
        </TableCell>
        <TableCell>
          <Chip
            color={color}
            label={status}
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      return (
                        <TableCell key={key}>{key}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {sortedObjKeys.map((key) => {
                      const value = match[key];
                      return (
                        <TableCell key={key} align="right">{JSON.stringify(value)}</TableCell>
                      );
                    })}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const AllMatches = (props, { className, ...rest }) => {
  const classes = useStyles();

  const { data, address } = props;
  const { joinedMatches, previousMatches, gameIdToNameLookup } = data;

  const matches = (joinedMatches).concat(previousMatches);
  matches.sort((a, b) => {
    const timeToUseA = a.endTime === 0 ? a.createdTime : a.endTime;
    const timeToUseB = b.endTime === 0 ? b.createdTime : b.endTime;

    if (timeToUseA < timeToUseB) {
      return 1;
    }
    return -1;
  });

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader title="All Matches" />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={800}
          height={430}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Match ID
                </TableCell>
                <TableCell>
                  Game
                </TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matches.map((order) => {
                return (
                  <Row
                    hover
                    key={order.Match_ID}
                    match={order}
                    address={address}
                    gameIdToNameLookup={gameIdToNameLookup}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <Divider />
      <Box
        display="flex"
        justifyContent="flex-end"
        p={2}
      />
    </Card>
  );
};

AllMatches.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  address: PropTypes.string,
};

Row.propTypes = {
  match: PropTypes.object,
  gameIdToNameLookup: PropTypes.object,
  address: PropTypes.string,
};

export default AllMatches;
